const angular = require('angular');
const angularJWT = require('angular-jwt');
const localStorageServiceModule = require('angular-local-storage');
const userServiceComponent = require('./user-service.service');
const angularResource = require('angular-resource');
const uiRouter = require('@uirouter/angularjs').default;
const devConfigurationModule = require('../dev-configuration/dev-configuration');

const goalServiceComponent = require('../goal-service/goal-service');

module.exports = angular
    .module('app.userService', [
      angularResource,
      angularJWT,
      uiRouter,
      devConfigurationModule,
      localStorageServiceModule,
      goalServiceComponent
    ])
    .service('userService', [
      '$resource',
      'devConfiguration',
      'localStorageService',
      '$http',
      '$rootScope',
      'authManager',
      '$state',
      '$window',
      'jwtHelper',
      '$location',
      '$timeout',
      userServiceComponent])
    .name;
