function config($urlRouterProvider, $translateProvider, $httpProvider, $locationProvider, localStorageServiceProvider, $mdGestureProvider, jwtOptionsProvider) {
    $translateProvider.useSanitizeValueStrategy(null);
    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '/locale/{lang}/{part}.json'
    });
    $translateProvider.preferredLanguage('en-ca');

    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode(true);

    localStorageServiceProvider.setStorageType('sessionStorage');

    $urlRouterProvider.otherwise((injector, location) => {
        if (location.url() !== '/') {
            location.url('/error/404');
        } else if (location.url() === '/' && !location.$$state) {
            window.location.href = '/';
        }
    });

    jwtOptionsProvider.config({
        //make sure to white list the DOMAINS!!!
        whiteListedDomains: ['localhost'],
        tokenGetter: ['localStorageService', function (localStorageService) {
            return localStorageService.get('authToken');
        }]
    });

    $httpProvider.interceptors.push('jwtInterceptor', 'ATBInterceptor');
    $mdGestureProvider.skipClickHijack();
}

module.exports = config;
