//VENDOR MODULES
const angular = require('angular');
const angularJWT = require('angular-jwt');
const angularMaterial = require('angular-material');
require('angular-material/angular-material.scss');
const angularTranslate = require('angular-translate');
const angularMessages = require('angular-messages');
require('angular-translate-loader-partial');
require('es6-promise/auto');

const dynamicLocale = require('angular-dynamic-locale');
const localStorageService = require('angular-local-storage');
const ocLazyLoad = require('oclazyload');
const uiRouter = require('@uirouter/angularjs').default;
//NON-VENDOR MODULES
const accountSelectionModule = require('./open-account/components/account-type/account-type');
const appConfig = require('./app.config');
const appRoute = require('./app.routes');
const appRun = require('./app.run');
const accessTokenModule = require('./access-token/access-token');
const ATBInterceptorModule = require('./services/ATBInterceptor/ATBInterceptor');
const errorModule = require('./error/error');
const eSignModule = require('./e-sign/e-sign');
const idVerificationModule = require('./id-verification/id-verification');
const landingPageModule = require('./landing-page/landing-page');
const fundAccountModule = require('./fund-account/fund-account');
const openAccountModule = require('./open-account/open-account');
const prosperDashboardModule = require('./prosper-dashboard/prosper-dashboard');
const ciamRegistrationModule = require('./registration-ciam/registration');
const startInvestingV2Module = require('./start-investing/v2/start-investing');
const educationBeneficiariesModule = require('./education-beneficiaries/education-beneficiaries');
const SuggestedFundModule = require('./suggested-fund/suggested-fund.module');
const KycModule = require('./kyc/kyc.module');
const VerifyEmailModule = require('./verify-email/verify-email.module');
const bannerModule = require('./components/banner/banner.module');
const ngStorage = require('./ng-storage.module')

require('../style/app.scss');

module.exports = angular
    .module('app', [
      ngStorage,
      accessTokenModule,
      accountSelectionModule,
      angularJWT,
      angularMaterial,
      angularTranslate,
      ATBInterceptorModule,
      angularMessages,
      dynamicLocale,
      educationBeneficiariesModule,
      errorModule,
      eSignModule,
      fundAccountModule,
      idVerificationModule,
      landingPageModule,
      localStorageService,
      ocLazyLoad,
      openAccountModule,
      prosperDashboardModule,
      startInvestingV2Module,
      uiRouter,
      SuggestedFundModule,
      KycModule,
      VerifyEmailModule,
      bannerModule,
      ciamRegistrationModule
    ])
    .factory('$exceptionHandler', () =>
        (exception) => {
          if (window.__env !== 'PROD') console.log(exception);
          window.track('Error', {
            message: exception.message || exception,
            stack: exception.stack
          })
        })
    .config(['$urlRouterProvider', '$translateProvider', '$httpProvider', '$locationProvider', 'localStorageServiceProvider', '$mdGestureProvider', 'jwtOptionsProvider', appConfig])
    .config(['$stateProvider', appRoute])
    .run(['tmhDynamicLocale', 'tmhDynamicLocaleCache', 'authManager', '$rootScope', 'localStorageService', '$state', '$mdDialog', '$transitions', 'userService', appRun]);
