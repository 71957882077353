class GoalServiceService {
    constructor($state, $localStorage, $resource, devConfiguration, utilityService, localStorageService) {
        this.baseUrl = devConfiguration.getURLRoot(window.location.port);
        this.goalKSResource = $resource(this.baseUrl + '/dashboard/goalKS/:goalId');
        this.editGoalDataResource = $resource(this.baseUrl + '/dashboard/retrieve_edit_goal_data');
        this.gwsContributionResource = $resource(this.baseUrl + '/gws/save-contribution');
        this.localStorageService = localStorageService;
        this.resource = $resource;
        this.devConfiguration = devConfiguration;
        this.state = $state;
        this.utilityService = utilityService;
        this.$localStorage = $localStorage;

        this.api = (path) => $resource(this.baseUrl + '/' + path);
    }

    createGoal(params) {
        return this.api('v2/goal').save(params).$promise;
    }

    deleteGoal(goalId) {
        return this.api('v2/goal/:goalId').remove({goalId: goalId}).$promise;
    }

    getActiveGoals() {
        return this.api('dashboard/webview').get().$promise;
    }

    getCurrentGoalId() {
        return this.localStorageService.get('currentGoalId')
    }

    setCurrentGoalId(goalId) {
        this.localStorageService.set('currentGoalId', goalId);
    }

    getGoalKS(goalId) {
        return this.goalKSResource.get({goalId: goalId}).$promise;
    }

    setDocuSignStatus(event) {
        this.localStorageService.set('docuSignStatus', event);
    }

    getDocuSignStatus() {
        return this.localStorageService.get('docuSignStatus')
    }

    getROI(goalId) {
        return this.api('v2/goal/:goalId/roi').get({goalId}).$promise;
    }

    removeDocuSignStatus() {
        return this.localStorageService.remove('docuSignStatus')
    }

    goToGoalInProgress(currentPage, goalId) {
        //TODO: we can save the traffic to read goal KS again...
        // we actually have goal KS. To do that, instead of saving
        // goalId. Saving the goalKS.
        this.setCurrentGoalId(goalId);
        let current = currentPage ? currentPage : 'OPENACCOUNT';
        if (current === 'IDVERIFICATION' || current === 'DOCUSIGN') {
            this.getGoalKS(goalId).then(data => {
                    if (this.utilityService.validatePacDate(data.goalKS)) {
                        this.state.go(this.utilityService.getPageByName(current).page);
                    } else {
                        this.state.go(this.utilityService.getPageByName('FUNDACCOUNT').page);
                    }
                }
            );
        } else {
            this.state.go(this.utilityService.getPageByName(current).page);
        }
    }

    verifyValidGoalNavigation(requestedPageName) {
        return this.getGoalKS(this.getCurrentGoalId()).then(data => {
            if (data.goalKS.goal.status === 'COMPLETED') {
                $state.go('prosper-dashboard');
            }
            this.verifyNaoPageOrder(requestedPageName, data.goalKS.goal.currentPage);
            return data;
        });
    }

    verifyNaoPageOrder(requestedPageName, goalPageName) {
        if (this.utilityService.getPageOrderByName(requestedPageName) >
            this.utilityService.getPageOrderByName(goalPageName)
        ) {
            this.state.go(this.utilityService.getPageByName(goalPageName).page);
        }
    }

    getImgUrl(goal) {
        try {
            return require('../../../public/img/goals/' + goal + '.jpg');
        } catch (error) {
            return undefined;
        }
    }

    resetAnswers() { // TODO: Update this to riskAssessmentV2AnswerModel
        this.localStorageService.remove('riskAssessmentAnswerModel');
    }

    doConvert() {
        return this.api('conversion').save().$promise;
    }

    getHideConversionPage() {
        return this.localStorageService.get('hideConversionPage');
    }

    hideConversionPage() {
        this.localStorageService.set('hideConversionPage', true);
    }

    /*
     * Util methods
     */
    canContinue(goalKs) {
        return goalKs.isNative && goalKs.goal.status !== 'COMPLETED' && !(
            // primary applicant can't continue while waiting on joint subscriber
            goalKs.isPrimaryApplicant && (goalKs.goal.currentPage === 'JOINT_INIT' || goalKs.goal.currentPage === 'JOINT_DOCUSIGN')
        );
    }

    canResend(goalKs) {
        return goalKs
            && goalKs.isNative
            && goalKs.isPrimaryApplicant
            && goalKs.goal.currentPage === 'JOINT_INIT';
    }

    getProgress(goalKs) {
        return Math.min(Math.floor(goalKs.calculatedTotal / goalKs.goal.goalAmount * 100), 100);
    }

    getPacs(goalId) {
        return this.editGoalDataResource.get({goalId: goalId}).$promise;
    }

    setHasGroupGoal(bool) {
        this.localStorageService.set('hasGroupGoal', bool);
    }

    saveGwsContribution(request) {
        return this.gwsContributionResource.save(request).$promise;
    }
}

module.exports = GoalServiceService;
