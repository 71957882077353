require('./access-token.scss');
require('../components/loading/loading.scss');

function routes($stateProvider) {
  $stateProvider
      .state('login', {
        url: '/login',
        parent: 'site',
        resolve: {
          transition: ['userService',
            (userService) => {
              userService.pingSignIn({})
            }]
        }
      })
      ///////////////
      .state('pingLoginSuccess', {
        url: '/pingLoginSuccess?code&state',
        parent: 'site',
        params: {
          code: null,
          state: null
        },
        views: {
          'content@': {
            template: require('../components/loading/loading.html'),
          }
        },
        resolve: {
          transition: ['$state', '$stateParams', 'userService', 'localStorageService',
            ($state, $stateParams, userService, localStorageService) => {
              userService.pingAuthenticate()
                  .then(() => userService.postLogin())
                  .then(() => $state.go('communication-preferences'))
                  .catch(e => {
                    window.mixpanel.track('Login - Failure', {
                      'loginFailureReason': 'Landing Page Load Error',
                      'platformUsed': 'Prosper Web',
                      'loginMethod': 'Typed Username Password',
                      'clientType': 'Wealth',
                      'userGUID': localStorageService.get('mpUserGUID')
                    });
                    $state.go('landing-page', e);
                  });
            }]
        }
      })
      .state('communication-preferences', {
        url: '/communication-preferences',
        parent: 'site',
        views: {
          'content@': {
            template: require('../components/communication-preferences/communication-preferences.html'),
            controller: 'ConsentController',
            controllerAs: 'controller'
          }
        },
        resolve: {
          localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
            $translatePartialLoader.addPart('consent');
            $translate.refresh();
            return true;
          }],
          controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
            return import(/* webpackChunkName: "welcome" */ '../components/communication-preferences/communication-preferences.controller')
                .then(controller => $ocLazyLoad.load(controller));
          }]
        }
      });
}

module.exports = routes;
