/* eslint-disable valid-jsdoc */
const moment = require('moment');
const {
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  RedirectRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  BaseTokenRequestHandler,
  TokenResponse
} = require('@openid/appauth');

class UserServiceService {
  constructor($resource,
              devConfiguration,
              localStorageService,
              $http,
              $rootScope,
              authManager,
              $state,
              $window,
              jwtHelper,
              $location,
              $timeout) {
    this.localStorageService = localStorageService;
    this.devConfiguration = devConfiguration;
    this.authManager = authManager;
    this.http = $http;
    this.state = $state;
    this.window = $window;
    this.location = $location;
    this.timeout = $timeout;
    this.urlRoot = devConfiguration.getURLRoot(this.window.location.port);
    this.api = (path) => $resource(this.urlRoot + '/' + path);
    this.angularJWT = jwtHelper;
    this.resource = $resource;
    this.userProfileResource = $resource(this.urlRoot + '/v2/user');
    this.userUpdateResource = $resource(this.urlRoot + '/user/update', {goalId: ''}, {
      put: {method: 'PUT'}
    });
    this.equifaxIdentityCheckResource = $resource(this.urlRoot + '/identity/equifax');
    this.identityCheckResource = $resource(this.urlRoot + '/identity/check');
    this.quickSaveResource = $resource(this.urlRoot + '/dashboard/quick-save');
    this.editGoalDataResource = $resource(this.urlRoot + '/dashboard/retrieve_edit_goal_data/');
    this.updateGoalResource = $resource(this.urlRoot + '/dashboard/update-goal');
    this.updateGoalPacResource = $resource(this.urlRoot + '/dashboard/update-pac');
    this.spousalInformation = $resource(this.urlRoot + '/v2/user/spousalInformation');
    this.sendRegistrationNotificationToSpouse = $resource(this.urlRoot + '/v2/user/sendRegistrationNotificationToSpouse');

    this.authorizationHandler = new RedirectRequestHandler();
    this.loginTimeout = null;
  }

  calculateAge(birthMonth, birthDay, birthYear) {
    if (birthMonth && birthDay && birthYear) {
      let formattedDate = (parseInt(birthMonth)) + '-' + birthDay + '-' + birthYear;
      let dobMoment = moment(formattedDate, 'MM-DD-YYYY');
      if (dobMoment.isValid()) {
        return moment().diff(dobMoment, 'years');
      }
    }
    return null;
  }

  signIn(userAction, params) {
    this.localStorageService.set('userAction', userAction);
    this.pingSignIn(params)
  }

  /**
   * Entry for ping sign in
   * @param userAction
   * @param params
   */
  pingSignIn(params) {
    this.pingAuthorization(
        this.window.applicationConfiguration.ping.clientID,
        this.window.location.origin + '/pingLoginSuccess',
        {...params, prompt: 'login'}
    )
  }

  /**
   * Clear PING user session. Without it, hitting /pingLogin will
   * automatically detect the PING userSession and automatically
   * sign in.
   *
   * If redirectUri is null, it will redirect back to landing page
   *
   * @param {string} redirectUri
   */
  pingSignOut(redirectUri) {
    const postSignOut = redirectUri || window.location.origin;
    AuthorizationServiceConfiguration.fetchFromIssuer(
        this.window.applicationConfiguration.ping.domain)
        .then(config => {
          this.window.location = `${config.endSessionEndpoint}?TargetResource=${postSignOut}`;
        });
  }

  initMixPanel(userGUID) {
    this.window.mixpanel.identify(userGUID);
    this.window.mixpanel.people.set({'userGUID': userGUID});
  }

  // TODO: rename this method to pingCompleteSignIn
  pingAuthenticate() {
    const clientId = this.window.applicationConfiguration.ping.clientID;
    const redirectUri = this.window.location.origin + '/pingLoginSuccess';
    return this.completePingAuthorizationFlow(clientId, redirectUri)
        .then((tokenResponse) => {
          const jwt = this.angularJWT.decodeToken(tokenResponse.idToken);
          const {externalUUID} = jwt;
          if (externalUUID) {
            this.initMixPanel(externalUUID);
          }
          this.localStorageService.set('mpUserGUID', externalUUID);
          this.localStorageService.set('usePing', true);
          this.localStorageService.set('authToken', tokenResponse.accessToken);
          this.authManager.authenticate();
          this.schedulePingTokenRenewal(
              clientId,
              redirectUri,
              tokenResponse.refreshToken,
              tokenResponse.expiresIn * 1000 - 30000);
        })
  }

  ///////////////////////////////////////////

  /**
   * Basic Ping Authorization Request
   * @param clientId
   * @param authorizationRedirect
   * @param extras
   */
  pingAuthorization(clientId, authorizationRedirect, extras) {
    const request = new AuthorizationRequest({
      client_id: clientId,
      redirect_uri: authorizationRedirect,
      scope: 'openid profile',
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      extras: {
        ...extras,
        response_mode: 'fragment'
      }
    });
    AuthorizationServiceConfiguration.fetchFromIssuer(
        this.window.applicationConfiguration.ping.domain)
        .then(config => {
          this.authorizationHandler.performAuthorizationRequest(config, request);
        });
  }

  /**
   * Basic Ping Token Exchange Request
   * @param clientId the same client ID used to make the authorization request
   * @param redirectUri the same redirect URI used to make the authorization
   *     request
   * @param authorizationRequest
   * @param authorizationResponse
   * @return {Promise<TokenResponse>}
   */
  pingTokenExchange(clientId, redirectUri, authorizationRequest, authorizationResponse) {
    const tokenHandler = new BaseTokenRequestHandler();
    const tokenRequest = new TokenRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: authorizationResponse.code,
      extras: {
        code_verifier: authorizationRequest.internal.code_verifier
      }
    });
    return AuthorizationServiceConfiguration.fetchFromIssuer(
        this.window.applicationConfiguration.ping.domain)
        .then(config => tokenHandler.performTokenRequest(config, tokenRequest))
  }

  /**
   * Basic Ping Token Renewal Request
   * @param refreshToken
   * @return {Promise<TokenResponse>}
   */
  pingTokenRenewal(clientId, redirectUri, refreshToken) {
    const tokenHandler = new BaseTokenRequestHandler();
    const refreshTokenRequest = new TokenRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: refreshToken
    });
    return AuthorizationServiceConfiguration.fetchFromIssuer(
        this.window.applicationConfiguration.ping.domain)
        .then(config => tokenHandler.performTokenRequest(config, refreshTokenRequest));
  }

  /**
   * Method to schedule Ping Token Renewal based on Token Expiry
   *
   * @param clientId
   * @param redirectUri
   * @param refreshToken
   * @param delay
   */
  schedulePingTokenRenewal(clientId, redirectUri, refreshToken, delay) {
    this.timeout(() => {
      this.pingTokenRenewal(clientId, redirectUri, refreshToken).then((tokenResponse) => {
        this.localStorageService.set('authToken', tokenResponse.accessToken);
        this.schedulePingTokenRenewal(clientId, redirectUri, tokenResponse.refreshToken, tokenResponse.expiresIn * 1000 - 30000);
      }).catch(() => {
        if (this.isUserLoggedOn()) {
          this.signOut(true);
        }
      });
    }, delay);
  }

  /**
   * Complete Authorization Flow
   * @param clientId
   * @param redirectUri
   * @param onTokenResponse
   * @return {Promise<TokenResponse>}
   */
  completePingAuthorizationFlow(clientId, redirectUri) {
    return new Promise((resolve, reject) => {
      const notifier = new AuthorizationNotifier();
      notifier.setAuthorizationListener((authorizationRequest, authorizationResponse, authorizationError) => {
        clearTimeout(this.loginTimeout);
        if (authorizationError) {
          reject({error: {message: 'feedbackMessage.loginErrorMessage.text'}});
        } else {
          this.pingTokenExchange(clientId, redirectUri, authorizationRequest, authorizationResponse)
              .then((tokenResponse) => {
                resolve(tokenResponse);
              });
        }
      });
      this.authorizationHandler.setAuthorizationNotifier(notifier);
      this.authorizationHandler
          .completeAuthorizationRequestIfPossible()
          .then(() => {
          });
      this.loginTimeout = this.timeout(() => reject(), 10000);
    });
  }

  setUserAction(userAction) {
    this.localStorageService.set('userAction', userAction);
  }

  setCurrentGoalId(goalId) {
    this.localStorageService.set('currentGoalId', goalId);
  }

  setAccountOpeningSession({goalId, hash}) {
    this.localStorageService.set('accountOpeningSession', {goalId, hash});
  }

  postLogin() {
    const session = this.localStorageService.get('accountOpeningSession');
    this.localStorageService.remove('accountOpeningSession');
    if (session) {
      this.setCurrentGoalId(session.goalId);
    }
    return this.api('v2/user/postLogin').save(session || {}).$promise;
  }

  signOut(inactivityLogout) {
    if (inactivityLogout) {
      AuthorizationServiceConfiguration.fetchFromIssuer(
          this.window.applicationConfiguration.ping.domain)
          .then(config => {
            this.window.location = `${config.endSessionEndpoint}?TargetResource=${window.location.origin}?inactivityLogout=true`;
          });
    } else {
      this.pingSignOut();
    }
  }

  clearStorage() {
    this.localStorageService.clearAll();
  }

  isUserLoggedOn() {
    try {
      return this.authManager.isAuthenticated();
    } catch (e) {
      this.signOut();
      return false;
    }
  }

  getUserDisplayName() {
    return this.localStorageService.get('userDisplayName');
  }

  removeUserDisplayName() {
    return this.localStorageService.remove('userDisplayName');
  }

  removeAuthToken() {
    return this.localStorageService.remove('authToken');
  }

  getInvestorProfileLocked() {
    return this.localStorageService.get('investorProfileLocked');
  }

  getUserAction() {
    return this.localStorageService.get('userAction');
  }

  removeUserAction() {
    return this.localStorageService.remove('userAction');
  }

  getUserProfile() {
    return this.userProfileResource.get().$promise;
  }

  updateUser(firstName, lastName) {
    return this.userUpdateResource.put({
      firstName: firstName,
      lastName: lastName
    }).$promise;
  }

  getEditGoalDetails(goalId) {
    return this.editGoalDataResource.get({goalId: goalId}).$promise
  }

  getSpousalInformation(goalId) {
    return this.spousalInformation.get({goalId: goalId}).$promise;
  }

  checkUserIdentification(goalId) {
    return this.identityCheckResource.save(goalId).$promise;
  }

  performEquifaxIdentityCheck(goalId) {
    return this.equifaxIdentityCheckResource.save(goalId).$promise;
  }

  signInAtbOnlineUser(token) {
    return this.api('atbol/userProfile').save(token).$promise;
  }

  quickSave({goalId, amount, fundCode}) {
    return this.quickSaveResource.save({goalId, amount, fundCode}).$promise;
  }

  updatePacGoal(body) {
    return this.updateGoalPacResource.save(body).$promise;
  }

  updateGoal(body) {
    return this.updateGoalResource.save(body).$promise;
  }

  /*
   * Registration
   */
  getUserData(hash) {
    return this.api('v2/registration').get({hash: hash}).$promise;
  }

  createPingUser(hash, body) {
    return this.api('v2/registration').save({
      hash: hash,
      body: body
    }).$promise;
  }

  resendRegistrationNotificationToSpouse(requestParams) {
    this.sendRegistrationNotificationToSpouse = this.resource(this.urlRoot + '/v2/user/sendRegistrationNotificationToSpouse');
    return this.sendRegistrationNotificationToSpouse.save(requestParams, {}).$promise;
  }

  /*
   * Consent APIs
   */
  getConsent() {
    return this.api('consent').get().$promise;
  }

  saveConsent(consent) {
    return this.api('consent').save(consent).$promise;
  }
}

module
    .exports = UserServiceService;
